import React, { useState, useEffect } from 'react';
import './Wysiwyg.scss';

export const Wysiwyg = props => {
  const { data } = props;
  const { content } = data;
  return (
    <div className="content-block">
      <div>
        <p className="content" dangerouslySetInnerHTML={{ __html: content.html }} />
      </div>
    </div>
  );
};
