import React from 'react';
import { Image } from 'components';
import './Suppliers.scss';

export const Suppliers = props => {
  const { data, items } = props;
  const { tagline } = data;
  const { image } = items;

  console.log(tagline);

  return (
    <div className="suppliers-block">
      {tagline && tagline.html && (
        <div className="suppliers-tagline" dangerouslySetInnerHTML={{ __html: tagline.html }} />
      )}
      <div className="image-container">
        {items.map(item => {
          const hasImage = item.image && (item.url || (item.image.localFile && item.image.localFile.id));
          return <div className="image-box">{hasImage && <Image image={item.image} className="image" />}</div>;
        })}
      </div>
    </div>
  );
};
