import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import { ProductGrid } from '../../components';
import './FeaturedProducts.scss';

export const FeaturedProducts = props => {
  const { data } = props;
  const { title, tagline, product_type: productType } = data;

  const { cardsQuery, keysQuery } = useStaticQuery(featuredQuery);

  const { products: cardProducts } = cardsQuery.edges[0].node;
  const { products: keyProducts } = keysQuery.edges[0].node;

  const productsArr = productType === 'Featured keys' ? keyProducts : cardProducts;

  return (
    <div className="featured-products">
      <div className="wrapper">
        <div className="featured-products-header">
          {tagline && tagline.html && <div className="tagline" dangerouslySetInnerHTML={{ __html: tagline.html }} />}
          {title && title.text && <h2 className="featured-products-title">{title.text}</h2>}
        </div>
        <ProductGrid products={productsArr} />
      </div>
    </div>
  );
};

const featuredQuery = graphql`
  query {
    cardsQuery: allShopifyCollection(filter: { title: { eq: "Featured - Cards" } }, limit: 3) {
      edges {
        node {
          products {
            shopifyId
            title
            handle
            description
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    keysQuery: allShopifyCollection(filter: { title: { eq: "Featured - Keys" } }, limit: 3) {
      edges {
        node {
          products {
            shopifyId
            title
            handle
            description
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
