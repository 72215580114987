import React, { useState, useEffect } from 'react';
import { Section, Wrapper, TextField, TextArea, Carousel, Maps } from 'components';
import { classNames, loop } from 'helpers';
import { Marker } from '@react-google-maps/api';
import './FormLong.scss';

// List required fields for each steps
// List required fields for each steps
const fieldsInit = {
  fullname: { required: true, value: null },
  email: { required: true, value: null },
  company: { required: true, value: null },
  comments: { required: false, value: null },
};

const ContactForm = () => {
  const [fields, setFields] = useState(fieldsInit);

  // Set Field when onChange
  const setField = (name, value, error) => {
    setFields({ ...fields, [name]: { ...fields[name], value, error } });
  };

  // Check if form can be submitted
  const readyToSubmit = Object.keys(fields).every(fieldName => {
    const field = fields[fieldName];
    if (field.error || (field.required && !field.value)) return false;
    return true;
  });

  return (
    <form
      className="contact-form"
      name="contact"
      method="post"
      action="https://a5w3o6c2j1.execute-api.ap-southeast-2.amazonaws.com/dev/"
    >
      <input type="hidden" name="_to" value="919e26b6aeb0100775d516de73fc9e2233" />
      <input type="hidden" name="_redirect" value="https://safedome.com/thank-you" />
      <input type="hidden" name="_sender" value="Safedome" />
      <input type="hidden" name="_formname" value="Contact Form" />
      <input type="hidden" name="_replyTo" value={fields.email.value} />

      <TextField className="form-half" name="fullname" label="Full name" onChange={setField} fields={fields} />
      <TextField className="form-half" name="email" label="Email" onChange={setField} fields={fields} />
      <TextField name="company" label="Company Name" onChange={setField} fields={fields} />
      <TextArea name="comments" label="Comments" onChange={setField} fields={fields} />
      <button type="submit" className="button secondary" disabled={!readyToSubmit}>
        Send
      </button>
    </form>
  );
};

export const FormLong = ({ data = {}, items }) => {
  const { title, subtitle, content } = data;
  const [maps, setMaps] = useState(null);
  const [location, setLocation] = useState(null);
  const initMarker = items && items[0] && items[0].coordinates;
  const initLat = initMarker && initMarker.latitude;
  const initLng = initMarker && initMarker.longitude;
  const initPos = { lat: initLat, lng: initLng };

  // Initial Maps - set bounds
  if (location !== 0 && !location && maps && typeof window !== 'undefined') {
    const gmaps = window && window.google && window.google.maps;
    const bounds = new gmaps.LatLngBounds();
    items.forEach(item => {
      const lat = item.coordinates && item.coordinates.latitude;
      const lng = item.coordinates && item.coordinates.longitude;
      bounds.extend(new gmaps.LatLng(lat, lng));
      maps.fitBounds(bounds);
    });
  }

  const setNewLocation = item => {
    if (!maps || typeof window === 'undefined') return null;
    setLocation(item.index);
    const lat = item.coordinates && item.coordinates.latitude;
    const lng = item.coordinates && item.coordinates.longitude;
    const gmaps = window && window.google && window.google.maps;
    const position = new gmaps.LatLng(lat, lng);
    maps.setCenter(position);
    maps.setZoom(15);
  };

  const icon = {
    path: 'M 0, 0 m -10, 0 a 10, 10 0 1, 0 20, 0 a 10, 10 0 1, 0 -20, 0',
    fillColor: '#06213d',
    fillOpacity: 0.5,
    scale: 1,
    strokeColor: '#06213d',
    strokeWeight: 2,
    strokeOpacity: 0.8,
  };

  return (
    <Section className="form-long" id="contact">
      <Wrapper className="form-long-wrapper">
        <div className="form-long-contact">
          <div className="form-long-title">{title && title.text}</div>
          <div className="form-long-subtitle">{subtitle && subtitle.text}</div>
          {content && content.html && content.html.length > 10 && (
            <div className="form-long-content" dangerouslySetInnerHTML={{ __html: content.html }} />
          )}
          <ContactForm />
        </div>
      </Wrapper>
      <div className="form-long-map">
        <Wrapper className="form-long-wrapper">
          <h2 className="form-long-map-title">FIND US</h2>
          <div className="locations">
            {loop(items, item => (
              <div
                key={item.key}
                className={classNames('location', { active: item.index === location })}
                onClick={() => setNewLocation(item.index === location ? items[0] : item)}
              >
                <div className="location-title">{item.title && item.title.text}</div>
                <div className="location-address">{item.address && item.address.text}</div>
                <div className="location-email">{item.email}</div>
              </div>
            ))}
          </div>
        </Wrapper>
        <Maps className="form-long-gmaps" mapsRef={ref => setMaps(ref)}>
          {loop(items, item => {
            const lat = item.coordinates && item.coordinates.latitude;
            const lng = item.coordinates && item.coordinates.longitude;
            return <Marker icon={icon} key={item.key} position={{ lat, lng }} />;
          })}
        </Maps>
      </div>
    </Section>
  );
};
