import React from 'react';
import { Section, Wrapper, Image, Icon } from 'components';
import { loop } from 'helpers';
import './ImageFeatures.scss';

export const ImageFeatures = props => {
  const { data, items } = props;
  return (
    <Section className="image-features">
      <div className="image-features-image">
        <Image image={data && data.image} />
      </div>
      <div className="image-features-features">
        <div className="features-container">
          {loop(items, item => (
            <div key={item.key} className="feature">
              <div className="feature-icon">
                <Icon title={item && item.icon} />
              </div>
              <div className="feature-text">{item && item.feature && item.feature.text}</div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};
