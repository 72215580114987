import React from 'react';
import { Section, Wrapper, Image } from 'components';
import { loop } from 'helpers';
import './Partners.scss';

export const Partners = props => {
  const { items } = props;

  return (
    <Section className="partners">
      <Wrapper>
        <div className="partners-items">
          {loop(items, item => {
            const { partner_name: name, partner_description: description, partner_image: image } = item;
            return (
              <div className="partner">
                <div className="partner-image">
                  <Image image={image} />
                </div>
                <div className="partner-title">{name && name.text}</div>
                <div className="partner-description">{description && description.text}</div>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </Section>
  );
};
