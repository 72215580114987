import React, { useState } from 'react';
import { Section, Wrapper, TextArea, TextField } from 'components';
import './FormShort.scss';

// List required fields for each steps
const fieldsInit = {
  fullname: { required: true, value: null },
  email: { required: true, value: null },
  company: { required: true, value: null },
  comments: { required: false, value: null },
};

export const FormShort = ({ data }) => {
  const { title, subtitle, content, submit } = data;
  return (
    <Section id="contact" className="form-short">
      <Wrapper className="form-short-wrapper">
        <div className="form-short-title">{title && title.text}</div>
        <div className="form-short-subtitle">{subtitle && subtitle.text}</div>
        {content && content.html && content.html.length > 10 && (
          <div className="form-short-content" dangerouslySetInnerHTML={{ __html: content.html }} />
        )}
        <ContactForm submit={submit} />
      </Wrapper>
    </Section>
  );
};

const ContactForm = props => {
  const [fields, setFields] = useState(fieldsInit);
  const { submit } = props;

  // Set Field when onChange
  const setField = (name, value, error) => {
    setFields({ ...fields, [name]: { ...fields[name], value, error } });
  };

  // Check if form can be submitted
  const readyToSubmit = Object.keys(fields).every(fieldName => {
    const field = fields[fieldName];
    if (field.error || (field.required && !field.value)) return false;
    return true;
  });

  return (
    <form
      className="contact-form"
      name="contact"
      method="post"
      action="https://a5w3o6c2j1.execute-api.ap-southeast-2.amazonaws.com/dev/"
    >
      <input type="hidden" name="_to" value="919e26b6aeb0100775d516de73fc9e2233" />
      <input type="hidden" name="_redirect" value="https://safedome.com/thank-you" />
      <input type="hidden" name="_sender" value="Safedome" />
      <input type="hidden" name="_formname" value="Contact Form" />
      <input type="hidden" name="_replyTo" value={fields.email.value} />

      <TextField className="form-half" name="fullname" label="Full name" onChange={setField} fields={fields} />
      <TextField className="form-half" name="email" label="Email" onChange={setField} fields={fields} />
      <TextField name="company" label="Company Name" onChange={setField} fields={fields} />
      <TextArea name="comments" label="Comments" onChange={setField} fields={fields} />
      <button type="submit" className="button secondary" disabled={!readyToSubmit}>
        {submit}
      </button>
    </form>
  );
};
