import React from 'react';
import { Section, Wrapper, Image } from 'components';
import { classNames, loop } from 'helpers';
import './Grid.scss';

const Items = ({ items }) => (
  <div className="grid-items">
    {loop(items, item => {
      const { title, description, image } = item;
      return (
        <div className="grid-item">
          <div className="item-image">
            <Image image={image} />
          </div>
          <div className="item-title">{title && title.text}</div>
          <div className="item-description" dangerouslySetInnerHTML={{ __html: description && description.html }} />
        </div>
      );
    })}
  </div>
);

export const Grid = ({ data, items }) => {
  const { title } = data;
  return (
    <Section className="grid">
      <Wrapper>
        <h2>{title && title.text}</h2>
        <Items items={items} />
      </Wrapper>
    </Section>
  );
};
