import React, { useEffect, useState } from 'react';
import { Section, CarouselSlick, Image } from 'components';
import { loop, useWindowSize } from 'helpers';
import './ImageSlider.scss';

// Breakpoints (DESC) for Slider heights
const breakpoints = [
  { breakpoint: 2200, height: 600 }, // Giant
  { breakpoint: 1600, height: 600 }, // Large desktop
  { breakpoint: 1340, height: 600 }, // Desktop
  { breakpoint: 1260, height: 400 }, // Small desktop
  { breakpoint: 1025, height: 400 }, // Large tablet
  { breakpoint: 769, height: 300 }, // Tablet
  { breakpoint: 660, height: 300 }, // Small rablet
  { breakpoint: 500, height: 280 }, // Large mobile
  { breakpoint: 400, height: 280 }, // Mobile
  { breakpoint: 340, height: 200 }, // Small mobile
  { breakpoint: 0, height: 200 }, // Small mobile
];

// Calculate Image Dimensions
const dimensions = (item, windowWidth) => {
  const requiredHeight = 600;
  const dimension = item && item.image && item.image.dimensions;
  const width = dimension && dimension.width;
  const height = dimension && dimension.height;
  if (!width || !height) return null;
  const breakpoint = breakpoints.find(value => value.breakpoint < windowWidth);
  const ratio = width / height;
  const rescale = breakpoint && breakpoint.height * ratio;
  return { height: breakpoint && breakpoint.height, width: rescale };
};

// Carousel Settings
const settings = {
  dots: true,
  infinite: true,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
};

export const ImageSlider = ({ items }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { width } = useWindowSize();

  return (
    <Section className="image-slider">
      <div className="image-wrapper">
        <CarouselSlick settings={settings}>
          {loop(items, item => (
            <div key={item.key} className="image-slider-item">
              <div className="image-slider-item-wrapper" style={mounted ? dimensions(item, width) : {}}>
                {item.image && <Image image={item.image} />}
              </div>
            </div>
          ))}
        </CarouselSlick>
      </div>
    </Section>
  );
};
