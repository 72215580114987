import React from 'react';
import { Image, Section, Wrapper } from 'components';
import { loop } from 'helpers';
import './Box.scss';

export const Box = ({ data, items }) => (
  <Section className="image-lists">
    <Wrapper>
      <h2 className="image-lists-title">What's in the box ?</h2>
      <div className="image-lists-items">
        {loop(items, item => (
          <div key={item.key} className="image-lists-item">
            <div className="image-lists-image">{item.image && <Image image={item.image} />}</div>
            <div className="image-lists-description">{item.image && item.image.alt}</div>
          </div>
        ))}
      </div>
    </Wrapper>
  </Section>
);
