import React, { useState } from 'react';
import { Section, Wrapper, Image, Link, Icon } from 'components';
import './ProductReview.scss';

export const ProductReview = props => {
  const { items } = props;

  const [showCount, setShowCount] = useState(3);
  const [hasShownMore, setHasShownMore] = useState(false);

  if (!items || items.length < 1) return null;

  const clonedItems = [...items];

  const showMore = event => {
    event.preventDefault();
    setShowCount(999);
    setHasShownMore(true);
  };

  const showLess = event => {
    event.preventDefault();
    setShowCount(3);
    setHasShownMore(false);
  };

  const canToggleReviews = showCount < items.length || hasShownMore;

  return (
    <Section className="review">
      <Wrapper className="review-wrapper">
        {clonedItems.splice(0, showCount).map(review => {
          const { avatar, date_posted: datePosted, name, review_text: reviewText, star_rating: starRating } = review;

          const hasAvatar = avatar && (avatar.url || avatar.localFile);
          const hasName = name && name.text;
          const hasRating = starRating;
          const nameAutoFill = !hasName ? 'Anonymous' : name;
          const starsAutoFill = !hasRating ? '5' : starRating;

          const starFill = () => {
            const starValue = starsAutoFill;
            const newWidth = starValue / 0.5;
            return `${newWidth}0%`;
          };

          return (
            <div className="individual-review-wrapper">
              <section className="review-content">
                {hasAvatar && <Image image={review.avatar} className="review-avatar" />}
                <div className="review-content-right">
                  {hasName && <h4 className="review-name">{name.text || nameAutoFill}</h4>}
                  <p>posted on {datePosted}</p>
                  <div className="empty-stars">
                    <div className="filled-stars" style={{ width: `${starFill()}` }} />
                  </div>
                  <p className="review-text">{reviewText.text}</p>
                </div>
              </section>
            </div>
          );
        })}
        {canToggleReviews && (
          <Link
            className={`review-show-more ${hasShownMore ? 'can-collapse' : ''}`}
            to="#show-more"
            onClick={hasShownMore ? showLess : showMore}
          >
            <Icon className="review-show-more-icon" title="ChevronDown" />
            {hasShownMore ? 'Show Less' : `Show More (+${items.length - showCount})`}
          </Link>
        )}
      </Wrapper>
    </Section>
  );
};
