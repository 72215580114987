import React from 'react'
import { Section, Wrapper, Image, Carousel } from 'components'
import { loop } from 'helpers'
import './ImageText.scss'

export const ImageText = ({ items, data }) => {
  const {
    title,
    link,
    link_url: linkUrl,
    description,
    apply_frame: applyFrame,
    background_colour: backgroundColour
  } = data

  const findBackgroundColour =
    backgroundColour === 'Off white' ? 'off-white' : 'white'

  return (
    <Section
      className={`image-text ${findBackgroundColour}`}
      frame={applyFrame === 'Yes'}
    >
      <Wrapper className='image-text-wrapper'>
        <div className='image-text-container'>
          <div className='image-text-slider-container'>{Images(items)}</div>
          <div className='image-text-content-container'>
            <div className='image-text-content-wrapper'>
              <h3 className='image-text-title'>{title && title.text}</h3>
              <div
                className='image-text-description'
                dangerouslySetInnerHTML={{
                  __html: description && description.html
                }}
              />
              {link && (
                <a
                  className='button seconddary'
                  href={(linkUrl && linkUrl.url) || '#contact'}
                >
                  {link}
                </a>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </Section>
  )
}

const Images = items => {
  if (items && items.length > 1) {
    return (
      <Carousel buttonsDisabled>
        {loop(items, item => (
          <div key={item.key} className='image-text-slide'>
            <Image image={item && item.image} />
          </div>
        ))}
      </Carousel>
    )
  }
  return items && items[0] && <Image image={items[0].image} />
}
