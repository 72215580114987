import React, { useState } from 'react';
import { classNames } from 'helpers';
import { Icon, Section, Wrapper } from 'components';
import './Faq.scss';

const Questions = items => {
  const [visible, toggleVisible] = useState(null);
  return items.map((item, index) => {
    const { question, answer } = item;
    const classes = classNames('faq-item', { visible: visible === index });
    return (
      <div key={index} className={classes} onClick={() => toggleVisible(visible !== index ? index : null)}>
        <div className="faq-item-wrapper">
          <div className="faq-question" role="button">
            <div className="faq-question-title">{question.text}</div>
            <div className="faq-question-icon">
              <Icon className="faq-icon" title="ChevronDown" />
            </div>
          </div>
          <div className="faq-answer">
            <p>{answer.text}</p>
          </div>
        </div>
      </div>
    );
  });
};

export const Faq = ({ items }) => (
  <Section className="faq">
    <Wrapper className="faq-wrapper">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <div className="faq-items">{Questions(items)}</div>
    </Wrapper>
  </Section>
);
