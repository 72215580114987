import React from 'react';
import { Section, Wrapper, Container, Icon } from 'components';
import './Features.scss';

const Feature = items =>
  items.map((item, index) => {
    const { icon, description } = item;
    return (
      <div key={index} className="feature-item">
        <div className="feature-icon">
          <Icon title={icon} />
        </div>
        <div className="feature-description">{description.text}</div>
      </div>
    );
  });

export const Features = ({ items }) => (
  <Section className="features">
    <Wrapper className="features-wrapper">
      <h2 className="features-title">Features</h2>
      <Container className="features-items">{Feature(items)}</Container>
    </Wrapper>
  </Section>
);
