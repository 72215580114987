import React from 'react';
import * as SlicesComponents from './index.js';

const SliceSelector = ({ data }) => {
  const { slice_type: sliceType } = data;

  // Convert slice type from snake_case to TitleCase
  if (!sliceType) return null;
  const sliceName = sliceType
    .split('_')
    .map(item => item.charAt(0).toUpperCase() + item.substring(1))
    .join('');

  const Slice = SlicesComponents[sliceName];
  if (!Slice) {
    console.warn(`Slice ${sliceName} could not be found. It is likely you have not exported the slice.`);
    return null;
  }

  const { primary, items, id } = data;
  if (process.env.NODE_ENV === 'development') {
    console.log('Slice ====', sliceName, { data: primary, items });
  }
  return <Slice data={primary} items={items} id={id} key={`slice_${id}`} />;
};

export const Slices = ({ data }) => {
  if (!data) return null;
  return data.map(slice => {
    if (!slice || !slice.id) return null;
    return <SliceSelector key={slice.id} data={slice} />;
  });
};
