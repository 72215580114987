import React, { useState, useEffect } from 'react';
import OnVisible from 'react-on-visible';
import YouTube from 'react-youtube';
import { CarouselSlick, Image, Link } from 'components';
import './Slider.scss';

export const Slider = ({ items, data }) => {
  const { theme, slider_type: sliderType } = data;

  const [sliderObj, setSliderObj] = useState(null);
  const [visible, setVisible] = useState(false);
  const [videoArr, setVideoArr] = useState([]);

  const setSliderRef = ref => {
    if (!sliderObj) {
      setSliderObj(ref);
    }
  };

  const slideTo = index => {
    if (sliderObj && sliderObj.current) {
      sliderObj.current.slickGoTo(index);
    }
  };

  const handleSlideChange = oldIndex => {
    const oldSlide = items[oldIndex];
    const { youtube_id: youtubeId = {} } = oldSlide;
    if (youtubeId.text) {
      const oldVideo = videoArr[oldIndex];
      oldVideo.target.pauseVideo();
    }
  };

  const handleVideoReady = (video, index, sub) => {
    const videos = [...videoArr];
    videos[index] = { ...video, sub };
    setVideoArr(videos);
  };

  const findType = () => {
    if (sliderType === 'Video') {
      return 'video-slider';
    }
    return 'image-slider';
  };

  const findTheme = () => {
    if (theme === 'Light') {
      return 'off-white';
    }
    return 'secondary';
  };

  return (
    <OnVisible
      className={`feature-slider ${findType()} ${findTheme()}`}
      onChange={isVisible => setVisible(isVisible)}
      percent={3}
    >
      <CarouselSlick
        sliderRef={setSliderRef}
        beforeChange={handleSlideChange}
        arrows={false}
        dots={false}
        infinite
        // autoHeight
      >
        {items.map((slide, index) => {
          const {
            youtube_id: youtubeId,
            image,
            tagline,
            title,
            content,
            link_text: linkText,
            link_target: linkTarget,
          } = slide;

          const hasVideo = youtubeId && youtubeId.text;
          const hasImage = image && image.localFile && image.localFile.id;
          const hasLink = linkTarget && linkTarget.url;

          return (
            <div key={slide.subtitle}>
              {hasImage && <Image image={image} className="feature-slide-image with-video" />}
              <div className="wrapper">
                <div className="feature-slide" key={slide.subtitle}>
                  {hasVideo && (
                    <div className="feature-slide-video">
                      <div className="feature-slide-video-container">
                        {visible && (
                          <YouTube
                            videoId={youtubeId.text}
                            onReady={video => handleVideoReady(video, index)}
                            onEnd={() => slideTo(index + 1 === items.length ? 0 : index + 1)}
                            opts={{
                              height: '410',
                              width: '729',
                              playerVars: {
                                autoplay: index === 0,
                                allowFullScreen: true,
                                rel: 0,
                                modestbranding: 1,
                                loop: 1,
                                showinfo: 0,
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="feature-slide-content">
                    <div>
                      {tagline && tagline.html && (
                        <div className="tagline" dangerouslySetInnerHTML={{ __html: tagline.html }} />
                      )}
                      {title && title.text && <span className="feature-slide-title">{title.text}</span>}
                      {content && content.html && (
                        <div className="feature-slide-text" dangerouslySetInnerHTML={{ __html: content.html }} />
                      )}
                      <div className="feature-slider-actions">
                        <div className="feature-slider-nav">
                          {items.map((item, slideInt) => (
                            <button
                              key={item.subtitle}
                              type="button"
                              className={`feature-slider-nav-item ${index === slideInt ? 'active' : 'inactive'}`}
                              onClick={() => slideTo(slideInt)}
                            >
                              {slideInt + 1}
                            </button>
                          ))}
                        </div>
                        {hasLink && (
                          <Link className="button" to={linkTarget.url}>
                            {linkText.text}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </CarouselSlick>
    </OnVisible>
  );
};
