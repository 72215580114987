import React, { useState, useEffect } from 'react';
import { Image as Img } from 'components';
import './Image.scss';

export const Image = ({ data }) => {
  return (
    <div className="image-container">
      <div className="image-box">
        <Img image={data && data.image} />
      </div>
    </div>
  );
};
