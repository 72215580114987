import React from 'react';
import { Section, Wrapper } from 'components';
import './Video.scss';

export const Video = ({ data }) => {
  const { title, url } = data;
  if (!url || !url.html) return null;

  return (
    <Section className="video">
      <Wrapper className="video-wrapper">
        <h2 className="video-title">{title && title.text}</h2>
        <div className="video-content">
          <div className="video-content-wrapper" dangerouslySetInnerHTML={{ __html: url && url.html }} />
        </div>
      </Wrapper>
    </Section>
  );
};
